$(document).ready(function () {
    
    var toogleElement = false;
    
    /* Main Menu - Mobile */
    var mm = false;
    $('#menu-toggle').click(function() {
        if( $('body').hasClass('panel') ) {
            if(mm) {
                $('.aside-menu').removeClass('aside-menu-active');
                mm = false;
            }
            else {
                $('.aside-menu').addClass('aside-menu-active');
                mm = true;
            }
        }
        else {
            if(mm) {
                $('.main-menu').slideUp();
                $('.right-menu').slideUp();
                mm = false;
            }
            else {
                $('.main-menu').slideDown();
                $('.right-menu').slideDown();
                mm = true;
            }
        }
    });
    
    //dropdown menu
    $('.dropdown-profile-toggle').click(function() {
        $('.dropdown-profile').slideToggle('fast');
    });
    $(document).on('click', function () {
        $('.dropdown-profile').slideUp('fast');
    });
    $('.dropdown-profile-toggle').on('click', function (event) {
        event.stopPropagation();
    });
   
    /*MENU*/
    if( !$('body').hasClass('panel') ) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();

            if( !isMobile() ) {
                if (st > lastScrollTop) {
                    $('header').css('margin-top', '-140px');
                    $('header').css('opacity', '.5');
                } else {
                    $('header').css('margin-top', '0');
                    $('header').css('opacity', '1');
                }
                lastScrollTop = st;

                if( st != 0){
                    $('header').addClass('fixed-header');
                }else{
                    $('header').removeClass('fixed-header');
                }
            }
        });
    }
    
    $('#home-form-search input').click(function() {
        $(this).next().slideDown();
        toogleElement = true;
    });
    $('#home-form-search label').click(function() {
        $(this).next().next().slideDown();
        toogleElement = true;
    });
    
    $('.input-select').on('click', 'li', function() {
        $(this).parent().prev().val( $(this).text() );
    });
    
    $(document).on('click', '#main-container', function(event) {
        if(toogleElement) {
            var t = event.target.className;
            if(!t.includes("input-select-input")) {
                $('.input-select').slideUp();
                toogleElement = false;
            }
        }
    });
    
    $(document).on('keyup', '.input-select-input', function(event) {
        var filters = {
            string : event.target.value,
            input : event.target.name
        }
        
        var classes = event.target.className;
        if(classes.includes("localization")) filters.input = 'localization';
        else if(classes.includes("category")) filters.input = 'category';
        
        if(filters.string.length > 3) {
            $.ajax({
                url: '/getSearchData.php',
                type: 'post',
                data: filters,
                success: function(response){
                    $('.input-select-' + filters.input).children().remove();
                    $('.input-select-' + filters.input).append(response);
                }      
            });
        }
    });
    

    /*SCROLL DOWN HOME*/
    $('.scroll-down-below').click(function() {
        $("html, body").animate({ scrollTop: 800 }, 600);
        return false;
    });
    
    $('a').click(function(e) {
        if( $(this).hasClass('toggle') === false ) {
            if( $(this).attr('href').indexOf('#') > -1 ) {
                e.preventDefault();
                $("html, body").animate({ scrollTop: $( $(this).attr('href') ).offset().top }, 1000);
                return false;
            }
        }
    });


    /*POLECANE KANCELARIE SET*/
    var polecane_set = 1;
    var sets = 2;
    $('#polecane-kancelarie .next').click(function() {

        $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('opacity', '0');

        setTimeout(function() {
            $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('display', 'none');

            if(polecane_set < sets){
                polecane_set++;
            }else if(polecane_set == sets){
                polecane_set = 1;
            }

            if(polecane_set == sets){
               $('#polecane-kancelarie .next').fadeOut();
               $('#polecane-kancelarie .prev').fadeIn();
            }
            if(polecane_set == 1){
                $('#polecane-kancelarie .prev').fadeOut();
                $('#polecane-kancelarie .next').fadeIn();
            }

            $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('display','inherit');
            setTimeout(function() {
                $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('opacity','1');
            },200);

        },500);

    });

    $('#polecane-kancelarie .prev').click(function() {
        $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('opacity', '0');

        setTimeout(function() {
            $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('display', 'none');

            if(polecane_set < sets){
                polecane_set++;
            }else if(polecane_set == sets){
                polecane_set = 1;
            }

            if(polecane_set == sets){
               $('#polecane-kancelarie .next').fadeOut();
               $('#polecane-kancelarie .prev').fadeIn();
            }
            if(polecane_set == 1){
                $('#polecane-kancelarie .prev').fadeOut();
                $('#polecane-kancelarie .next').fadeIn();
            }

            $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('display','inherit');
            setTimeout(function() {
                $('#polecane-kancelarie .set:nth-of-type('+polecane_set+')').css('opacity','1');
            },200);

        },500);
    });


    /*QA*/
    var current_qa = 1;
    var qa_count = 5;
    var qas_height = $('#qas').height();
    $('#next-qa').click(function() {

        $('#qas').css('min-height', qas_height+'px');
        hide_qa(current_qa);

        if(current_qa < qa_count){
            current_qa++;
        }else if(current_qa == qa_count){
            current_qa = 1;
        }
        show_qa(current_qa);

        qas_height = $('#qas').height();
        $('#qas').css('min-height', qas_height+'px');
    });


    $('#prev-qa').click(function() {
        $('#qas').css('min-height', qas_height+'px');
        hide_qa(current_qa);

        if(current_qa > 1){
            current_qa--;
        }else if(current_qa == 1){
            current_qa = qa_count;
        }
        show_qa(current_qa);

        qas_height = $('#qas').height();
        $('#qas').css('min-height', qas_height+'px');
    });

    function hide_qa(qa) {
        $('#qa-loop .qa:nth-of-type('+qa+') .answer').fadeOut();
        $('#qa-loop .qa:nth-of-type('+qa+')').delay( 300 ).fadeOut();
        $('#qas-pag li:nth-of-type('+qa+')').removeClass('active');
    }
    function show_qa(qa) {
        $('#qas-pag li:nth-of-type('+qa+')').addClass('active');
        $('#qa-loop .qa:nth-of-type('+qa+')').delay( 1000 ).fadeIn();
        $('#qa-loop .qa:nth-of-type('+qa+') .answer').delay( 1000 ).fadeIn();
    }




    /*WYROZNIENI PRAWNICY TABS*/
    var wyroznieni_height = $('wyroznieni').height();
    $('#wyroznieni-prawnicy-filter li').click(function() {
        wyroznieni_height = $('#wyroznieni').height();
        $('#wyroznieni').css('min-height', wyroznieni_height+'px');

        $('#wyroznieni-prawnicy-filter li').removeClass('active');
        $(this).addClass('active');

        var tab = $(this).attr('tab');
        if(tab == 'wszyscy'){
            $('#wyroznieni .next').fadeIn();
            $('#wyroznieni .offer').fadeIn();
            $('#wyroznieni .offer:nth-of-type(n+9)').css('display', 'none');

        }
        else if(tab == 'adwokaci'){
            $('#wyroznieni .next').fadeOut();
            $('#wyroznieni .offer').fadeOut();
            $('#wyroznieni .adwokat').delay( 400 ).fadeIn();
        }
        else if(tab == 'radcowie'){
            $('#wyroznieni .next').fadeOut();
            $('#wyroznieni .offer').fadeOut();
            $('#wyroznieni .radca').delay( 400 ).fadeIn();
        }
    });
    $('#wyroznieni-prawnicy .next').click(function() {
        $('#wyroznieni .next').fadeOut();
        $('#wyroznieni .prev').fadeIn();
        $('#wyroznieni .offer').fadeIn();
        $('#wyroznieni .offer:nth-of-type(-n+8)').css('display', 'none');
    });
    $('#wyroznieni-prawnicy .prev').click(function() {
        $('#wyroznieni .prev').fadeOut();
        $('#wyroznieni .next').fadeIn();
        $('#wyroznieni .offer').fadeIn();
        $('#wyroznieni .offer:nth-of-type(n+9)').css('display', 'none');
    });



    /* REKOMENDCJE SLIDER */
    $('#testimonial-loop').slick({
        infinite        : true,
        autoplay        : true,
        pauseOnHover    : false,
        speed           : 500,
        autoplaySpeed   : 2500,
        slidesToShow    : 1,
        slidesToScroll  : 1,
        mobileFirst     : true,
        dots            : true,
        adaptiveHeight  : false,
        prevArrow: false,
        nextArrow: '<div class="next"><img src="prod/img/arrow-right-light.png" alt="Następna"></div>'
    });
   
    

    /* Content box TABS */
    var activeTab = 1;
    $('.content-box-tabs .tab').click(function() {
        var toggle_index = $(this).index()+1;
        
        if(activeTab !== toggle_index){
            $(this).parent().find('.active').removeClass('active');
            $(this).addClass('active');
            
            $('.content-box-tabs .elements .tab-content:nth-of-type(' + activeTab + ')').fadeOut();
            
            activeTab = toggle_index;
            
            setTimeout(function() {
                $('.content-box-tabs .elements .tab-content:nth-of-type(' + activeTab + ')').fadeIn();
            }, 500);
        }
    });
    


    /* INIT POPUPS */
    $(document).on('click', '.init-popup', function() {
        $('.popup').fadeOut();
        var popup = '#';
        popup += $(this).attr('popup');
        $(popup).fadeIn();
        
        if( isMobile() ) {
            if(mm) {
                $('.main-menu').slideUp();
                $('.right-menu').slideUp();
                mm = false;
            }
        }
    });
    
    $('.close-popup').click(function() {
        $('.popup').fadeOut();
        
        setTimeout(function(){
            $('.form-message').hide();
            $('.dm-form').show();
        },1000);
    });
    
    $(document).on('click', '.popup', function(event) {
        if(event.target.className == 'popup__inner') {
            $('.popup').fadeOut();
        }
    });
    
    
    //send AJAX fORM
    $(document).on('submit', '.dm-form', function(event) {
        event.preventDefault();
        
        if($("form")[0].checkValidity()) {
            var formData = {
                name : $('#dm-form-email').val(),
                message : $('#dm-form-message').val()
            };

            $.ajax({
                url: 'sendDm.php',
                type: 'post',
                data: formData,
                success: function(response){
                    $('#wyslij-wiadomosc-popup .dm-form').slideUp();
                    $('#wyslij-wiadomosc-popup .form-message-success').slideDown();
                },
                error: function(response){
                    $('#wyslij-wiadomosc-popup .dm-form').slideUp();
                    $('#wyslij-wiadomosc-popup .form-message-failed').slideDown();
                }
            });
        }
    });
    
    
//    Submit sort form after change 
    $('#sort-by select').on('change', function() {
        $('#sort-by').submit();
    });
    
    $('.show-phone').click(function() {
        $(this).children('.cover').slideUp();
        $(this).children('.content').delay( 500 ).fadeIn();
    });
    
    //polecani prawnicy widget slider
    if ( !isPanel() ) {
        $('.ppw-loop').slick({
            infinite        : true,
            autoplay        : true,
            pauseOnHover    : false,
            speed           : 500,
            autoplaySpeed   : 2500,
            slidesToShow    : 1,
            slidesToScroll  : 1,
            mobileFirst     : true,
            arrows          : false,
            dots            : true,
            adaptiveHeight  : false
        });
    }

    
    
    /* Helpers */
    function isMobile() {
        return $(window).width() < 767 ? true : false;
    }
    
    function isPanel() {
        return $('body').hasClass('panel')
    }
    
});
